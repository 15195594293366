<template>
  <div class="opertion_wrap">
    <div class="head">
      <div class="left">
        <span style="font-weight:700">题目ID:{{question.question_id}} </span>
        <span>（ {{question.question_source_year}} , {{question.question_type_name}} )</span>
        <!-- {{$rands(question.question_id)}} -->
      </div>
      <div class="right">
        <span class="question_score">
          题目分数：{{question.question_score}}
        </span>
      </div>
    </div>
    <div class="content">
      <div class="coll_wrap">
        <div class="topic_content">
          <p class="title">题目内容
          </p>

          <div class="topic"
               v-html="question.question_content"></div>
        </div>
        <el-collapse v-model="activeNames"
                     @change="handleChange">

          <el-collapse-item title="题目解析"
                            name="1">
            <div class="topic"
                 v-html="question.question_explain"></div>
          </el-collapse-item>
          <el-collapse-item title="题目答案"
                            name="2">
            <div class="topic"
                 v-html="question.question_answer"></div>
          </el-collapse-item>

        </el-collapse>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
      num: 0,
      activeNames: []
    }
  },
  props: {

    question: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      let topicContentTd = document.getElementsByTagName('td')
      if (topicContentTd && topicContentTd.length) {
        for (let i = 0; i < topicContentTd.length; i++) {
          if (topicContentTd[i].width) {
            topicContentTd[i].width = topicContentTd[i].width * 18 / 16
          }
        }
      }
    })
  },
  methods: {
    handleChange () { },
  }
}
</script>

<style lang="scss" scoped>
.opertion_wrap {
  position: relative;
  padding-bottom: 16rem;
  border-bottom: 1px dashed #ccc;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30rem;
    min-height: 68rem;
    background: #eff8ff;
    .left {
      flex: 1;
      text-indent: 10rem;
      font-size: 20rem;
      padding: 6rem 0;
      line-height: 34rem;
      font-weight: 500;
      color: #333333;
      align-items: center;
      span {
        display: inline-block;
      }
    }
    .right {
      width: 220rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      .question_score {
        display: inline-block;
        font-size: 20rem;
        font-weight: bold;
        color: #000000;
        margin-right: 30rem;
      }
    }
  }
  .content {
    padding: 0 30rem 0 10rem;
    background: #ffffff;
    margin-left: 30rem;
    .coll_wrap {
      margin-top: 30rem;
    }
  }
}

::v-deep .el-collapse-item__header {
  background: #f6f6f6;
  border-radius: 10rem;
  font-size: 20rem;
  font-weight: 500;
  color: #333333;
  text-indent: 20rem;
  margin-top: 20rem;
}
::v-deep .el-collapse-item__wrap {
  border-bottom: none;
}
::v-deep .el-collapse {
  border: none;
}
::v-deep .el-collapse-item__content {
  margin-left: 20rem;
  margin-top: 20rem;
}
::v-deep .el-collapse-item__arrow.is-active {
  margin-top: -20rem;
}
.topic {
  font-size: 20rem;
  ::v-deep p {
    font-size: 20rem;
    line-height: 2em;
  }
}
.title {
  font-size: 20rem;
  font-weight: 500;
  color: #666666;
  padding-bottom: 10rem;
}
</style>