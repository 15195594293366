<template>
  <div class="analysis_warp">
    <div v-if="list.length>0">
      <div class="top">
        <TopCard ref="TopCardRef"
                 text="题目详情"
                 :showBack="true" />
      </div>

      <div class="content"
           v-if="list[0].question_head_content">
        <div class="left">

          <div class="topic_content">
            <p class="title">题目ID :{{$route.query.topic_id}} <span>（ {{list[0].question_source_year}} , {{list[0].question_type_name}} )</span>
            </p>
            <div class="topic"
                 v-html="list[0].question_head_content"></div>
          </div>
        </div>
        <div class="right">
          <TopicInfo ref="TopicInfoRef"
                     v-for="item,index in list"
                     :key="index"
                     :question="item"
                     :opertion_heigth="0" />
        </div>
      </div>
      <div class="content1"
           v-else>
        <TopicInfo ref="TopicInfoRef"
                   v-for="item,index in list"
                   :key="index"
                   :question="item"
                   :opertion_heigth="0" />

      </div>
    </div>
  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import TopicInfo from './components/topicInfo.vue'
import { getTopic } from '@/api/topic.js'
export default {
  data () {
    return {
      list: [],
      info: {}
    }
  },
  components: {
    TopCard, TopicInfo
  },

  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const { data } = await getTopic({
        question_id: this.$route.query.topic_id
      })
      if (data) {
        this.list = data.list
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.analysis_warp {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #f6f6f6;
  ::v-deep img {
    vertical-align: middle;
  }
  .content1 {
    background: #ffffff;
    padding: 10rem;
    margin: 0 30rem;
    height: calc(100vh - 130rem);
    overflow: auto;
  }

  .top {
    width: 100vw;
    height: 100rem;
  }
  .content {
    display: flex;
    height: calc(100vh - 100rem);
    overflow: auto;
    .left {
      flex: 1;
      background: #ffffff;
      margin-left: 50rem;
      height: calc(100vh - 130rem);
      overflow: auto;
      .topic_content {
        padding: 30rem;
        overflow: auto;
        .title {
          font-size: 20rem;
          font-weight: 500;
          color: #666666;
        }
        .head {
          font-size: 20rem;
          font-weight: 500;
          color: #000000;
          margin-top: 20rem;
        }
        div {
          margin-top: 20rem;
        }
      }
    }
    .right {
      flex: 1;
      background: #ffffff;
      margin-left: 30rem;
      margin-right: 30rem;
      height: calc(100vh - 130rem);
      overflow: auto;
    }
  }
}

.topic {
  font-size: 20rem;
}
/* 整个滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* 滚动条上的按钮 (上下箭头). */
::-webkit-scrollbar-button {
  display: none;
}
/* 滚动条上的滚动滑块. */
::-webkit-scrollbar-thumb {
  background-color: rgba(202, 202, 210, 0.8);
  border-radius: 50px;
  cursor: pointer;
}
/* 滚动条没有滑块的轨道部分 */
::-webkit-scrollbar-track-piece {
  border-radius: 20px;
}
/* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>